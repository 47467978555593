<template>
	<div>
		<div class='row'>
			<div class='col-md-6'>
				<div class="card widget-contacts">
					<div class="card-body">
						<h4 class="card-title">Account Information</h4>
						<h6 class="card-subtitle mb-4">Your account details are as follows.</h6>
						
						<div class="form-group">
							<label>Email</label>
							<input type="email" disabled class="form-control disabled" v-model='user.email'>
						</div>
						
						<div class="form-group">
							<label>Name <sup class='text-danger'>*</sup></label>
							<input :class="{'is-invalid': get_error('name').length > 0}" type="text" name='name' class="form-control" placeholder='Your Name' v-model='user_name'>
							<span v-show="get_error('name').length > 0" class="text-danger">{{ get_error('name') }}</span>
						</div>

						<div class="form-group">
							<label>Phone Number</label>
							<input type="text" name='phone' class="form-control" placeholder='Ex. (123) 555-1234' v-model='user_phone'>
						</div>

						<SuccessMessage :message='success_details' v-if='success_details.length > 0' />
						<ErrorMessage :message='error_details' v-if='error_details.length > 0' />

						<div class="mt-4 text-right">
							<button @click='update_details' class="btn btn-primary">Update Details</button>
						</div>
					</div>
				</div><!-- end card -->
			</div>

			<div class='col-md-6'>
				<div class="card widget-contacts">
					<div class="card-body">
						<h4 class="card-title">Password Management</h4>
						<h6 class="card-subtitle mb-4">Update your account credentials.</h6>

						<div class="form-group">
							<label>Old Password <sup class='text-danger'>*</sup></label>
							<input :class="{'is-invalid': get_error('password').length > 0}" type="password" name='password' class="form-control" placeholder='Old Password' v-model='old_pass'>
							<span v-show="get_error('password').length > 0" class="text-danger">{{ get_error('password') }}</span>
						</div>

						<div class="form-group">
							<label>New Password <sup class='text-danger'>*</sup></label>
							<input :class="{'is-invalid': get_error('new_password').length > 0}" type="password" name='new_pass' class="form-control" placeholder='New Password' v-model='new_pass'>
							<span v-show="get_error('new_password').length > 0" class="text-danger">{{ get_error('new_password') }}</span>
						</div>

						<div class="form-group">
							<label>Confirm Password <sup class='text-danger'>*</sup></label>
							<input :class="{'is-invalid': get_error('confirm_password').length > 0}" type="password" name='confirm_pass' class="form-control" placeholder='Confirm Password' v-model='confirm_pass'>
							<span v-show="get_error('confirm_password').length > 0" class="text-danger">{{ get_error('confirm_password') }}</span>
						</div>
						
						<SuccessMessage :message='success_pwd' v-if='success_pwd.length > 0' />
						<ErrorMessage :message='error_pwd' v-if='error_pwd.length > 0' />

						<div class="mt-4 text-right">
							<button @click='update_password' class="btn btn-primary">Change Password</button>
						</div>
					</div>
				</div><!-- end card -->
			</div>
		</div>

	</div>
</template>

<style scoped lang='scss'>
	.actions{
		right: 24px !important;
	}
</style>

<script>

	import { mapGetters } from "vuex";
	import store from "@/store";

	import PermissionService from "@/common/permission.service";
	import SuccessMessage from '@/components/General/SuccessMessage';
	import ErrorMessage from '@/components/General/ErrorMessage';

	import { UPDATE_SETTINGS } from "@/store/actions.type";

	export default {
		name: 'Settings',
		components: {
			SuccessMessage,
			ErrorMessage
		},
		data(){
			return {
				user_name: '',
				user_phone: '',
				old_pass: '',
				new_pass: '',
				confirm_pass: '',
				success_details: '',
				success_pwd: '',
				error_pwd: '',
				error_details: '',
				errors: {
					password: '',
					new_password: '',
					confirm_password: '',
					update: '',
					name: ''
				}
			};
		},
		beforeMount() {
			this.user_name = this.user.name;
			this.user_phone = this.user.phone;
		},
		computed: {
			...mapGetters(['user', 'loading', 'election'])
		},
		methods: {
			getPermission(category, permission){
				return PermissionService.getPermission(category, permission);
			},
			get_error(key){
				if(this.errors[key] !== undefined) return this.errors[key];
				return '';
			},
			update_password(){
				this.errors = {
					password: '',
					new_password: '',
					confirm_password: '',
					update: '',
					name: ''
				};
				this.error_details = '';
				this.success_pwd = '';
				this.error_pwd = '';
				this.success_details = '';

				var dirty = false;
				var updatable = {};

				// Validate passwords if any one has data
				if(this.old_pass.length > 0 || this.new_pass.length > 0 || this.confirm_pass.length > 0){
					if(this.old_pass.length < 2){
						this.errors.password = "Please enter your current password.";
						return;
					}
					var pwd_msg = "Password should be at least 8 characters in length, and should contain at least one number or symbol.";
					if(this.new_pass.length < 8){
						this.errors.new_password = pwd_msg;
						return;
					}
					// Check if there are no numbers, and if there are no symbols
					if(!/\d/.test(this.new_pass) && /^[a-z0-9]+$/i.test(this.new_pass)){
						this.errors.new_password = pwd_msg;
						return;
					}
					if(this.new_pass != this.confirm_pass){
						this.errors.confirm_password = "Your confirmation password does not match.";
						return;
					}

					dirty = true;
					updatable.password = this.old_pass;
					updatable.new_password = this.new_pass;
				}

				// Finished validation, check if we need to update anything
				if(dirty){
					store.dispatch(UPDATE_SETTINGS, updatable).then(() => {
						this.old_pass = '';
						this.new_pass = '';
						this.confirm_pass = '';
						this.success_pwd = 'Your password has been updated';
					}).catch(message => {
						this.success = '';
						this.error_pwd = message;
					});
				}
			},
			update_details(){
				this.errors = {
					password: '',
					new_password: '',
					confirm_password: '',
					update: '',
					name: ''
				};
				this.error_details = '';
				this.success_pwd = '';
				this.error_pwd = '';
				this.success_details = '';

				var dirty = false;
				var updatable = {};

				// Validate user fields
				if(this.user_name.length < 3) {
					this.errors.name = "Please enter a valid name.";
					return;
				}

				if(this.user_name != this.user.name) {
					updatable.name = this.user_name;
					dirty = true;
				}
				if(this.user_phone != this.user.phone) {
					updatable.phone = this.user_phone;
					dirty = true;
				}
				
				// Finished validation, check if we need to update anything
				if(dirty){
					store.dispatch(UPDATE_SETTINGS, updatable).then(() => {
						this.success_details = 'Your account information has been updated';
					}).catch(message => {
						this.success = '';
						this.error_details = message;
					});
				}
			}
		}
	} 
</script>