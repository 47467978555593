<template>
	<div class='mb-3 alert alert-success fade show'>
		{{message}}
	</div>
</template>

<script>
export default {
	name: "SuccessMessage",
	props: {
		title: String,
		message: String,
		dissapear: {
			type: Boolean,
			default: true
		}
	},
	mounted(){
		if(this.dissapear){
			setTimeout(this.close_msg, 5000);
		}
	},
	methods: {
		close_msg() {
			// destroy the vue listeners, etc
			this.$emit('cancel');
		}
	}
};
</script>