<template>
	<div class='mb-3 alert alert-danger fade show my-message'>
		{{message}}
	</div>
</template>

<script>
export default {
	name: "ErrorMessage",
	props: {
		title: String,
		message: String
	}
};
</script>

<style scoped lang="css">
	.my-message{
		color: black !important;
		font-size: 16px;
	}
</style>