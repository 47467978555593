import store from "@/store";

export const getPermission = (cat, perm) => {
	let PERMISSION_LIST = store.getters.user.permissions;
	if(PERMISSION_LIST !== undefined && PERMISSION_LIST[cat] !== undefined && PERMISSION_LIST[cat][perm] !== undefined) {
		// Return permission
		return PERMISSION_LIST[cat][perm];
	} else {
		return false;
	}
};

export default { getPermission };  